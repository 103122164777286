import React from "react";
import { graphql } from "gatsby";

// JS Logic
import { getLocale } from "~utils";

// Components
import Layout from "~layout";
import TermsList from "~components/terms/termsList";

const CategoryPage = ({ data, location }) => {
  const { edges: entries } = data.allMarkdownRemark;

  const {
    frontmatter: page,
    frontmatter: { metadata, locale: localeKey },
  } = data.markdownRemark;

  const locale = getLocale(localeKey);

  return (
    <Layout helmetOnly={true} location={location} locale={locale} pageMetadata={metadata}>
      <TermsList locale={locale} page={page} entries={entries} />
    </Layout>
  );
};

export default CategoryPage;

export const pageQuery = graphql`
  query CategoryPage($locale: String, $category: String) {
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "terms-category-page" }
        templateCategory: { eq: $category }
        locale: { eq: $locale }
      }
    ) {
      frontmatter {
        locale
        metadata {
          title
          description
        }
        noEntries
        image {
          name
          publicURL
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "terms-page" }
          templateCategory: { eq: $category }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            metadata {
              title
              description
            }
            date(formatString: "MM / DD / YYYY")
          }
        }
      }
    }
  }
`;
