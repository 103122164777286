import React from "react";
import { Link } from "gatsby";

import { localizedSlug, useWindowSize } from "~utils";

import RenderImage from "~wrappers/renderImage";

const TermsList = ({ locale, page: { metadata, noEntries, image }, entries }) => {
  const { height: pageHeight } = useWindowSize();

  const renderTermsList = () => {
    if (!entries.length) {
      return <p>{noEntries}</p>;
    } else {
      return entries.map((entry, index) => (
        <div key={index} className="stf-terms__list__entry">
          <Link to={localizedSlug(entry.node.fields.slug)}>
            {entry.node.frontmatter.metadata.title}
          </Link>
          <span>{entry.node.frontmatter.date}</span>
        </div>
      ));
    }
  };

  if (!locale || !metadata || !noEntries || !image || !entries) {
    return;
  }

  return (
    <section
      className="stf-terms"
      style={{
        height: pageHeight,
      }}
    >
      <div className="stf-container">
        <div className="columns is-centered">
          <div className="column is-7">
            <div className="stf-terms__visual">
              <RenderImage image={image} />
            </div>
          </div>
          <div className="column is-5">
            <div className="stf-terms__overview">
              <h1>{metadata.title}</h1>
              <p>{metadata.description}</p>
              <div className="stf-terms__list">{renderTermsList()}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TermsList;
